import { getImage } from '../utils/utils';
import FormInput from './form-input'


const TopRankingItem = ({contestant, rank, score, c1, c2}) => {
    const {name, number} = contestant;
    
    return (
        <tr className='is-size-5 has-text-vcentered'>
            <td>{rank}</td>
            <td>
                <figure className="image is-4by5" style={{overflow: "hidden"}}>
                    <img src={getImage(number)} alt={name} style={{height: "initial"}}/>
                </figure>
            </td>
            <td className='has-text-weight-medium has-text-left'>#{number + " " + name}</td>
            <td>{c1}</td>
            <td>{c2}</td>
            <td>{score.toFixed(2)}</td>
        </tr>
    )
}

export default TopRankingItem;