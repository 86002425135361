import { useState, useEffect, useContext } from 'react';
import { getContestants, getJudges, setTopContestants } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useNavigate } from 'react-router-dom'
import ResultsTop10tem from '../components/results-top10-item';
import html2canvas from 'html2canvas';
import { title } from '../utils/utils';

const RankingTop5 = () => {
    const [list, setList = () => []] = useState([]);
    const [judges, setJudges = () => []] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants("Top5", "score");
            setList(docs);
        };
        getDocs();
    }, []);

    useEffect(() => {
        async function getDocs() {
            const docs = await getJudges();
            setJudges(docs);
        };
        getDocs();
    }, []);

    const onItemAddHandler = async(item) => {
        const existing = selected.find((sel) => sel.id === item.id);
        if (existing) {
            setSelected(selected.filter((sel) => {return sel.id !== item.id}));
            return;
        }

        if (selected.length >= 1) {
            return;
        }

        selected.push(item);
        setSelected([...selected]);
    }

    const onPrintHandler = () => {
        var printContents = document.getElementById("print");
    
        html2canvas(printContents, {scale: 3}).then(function(canvas) {
          var myImage = canvas.toDataURL('image/png');
    
          var mywindow = window.open('PRINT');
    
          mywindow.document.write('<html><head><title>' + document.title  + '</title>');
          mywindow.document.write('</head><body >');
          mywindow.document.write('<img src="' + myImage + '" style="width: 100%"/>');
          mywindow.document.write('</body></html>');
      
          setTimeout(() => {
            mywindow.document.close();
            mywindow.focus();
        
            mywindow.print();
            mywindow.close();
          }, 10)
        })
      }

    return (
        <div className='column is-12'>
            <div id='print'>
            <p className='is-size-4 has-text-centered'>Top 5</p>
            <h1 className='is-size-1 has-text-weight-bold has-text-centered mb-6'>{title}</h1>

            <div className="table-container mt-6">
                <table className="table is-fullwidth is-hoverable is-bordered has-text-centered is-selectable">
                    <thead>
                        <tr className='has-text-vcentered'>
                            <th rowSpan="3">#</th>
                            <th rowSpan="3">Name</th>
                            <th colSpan="10">Judges</th>
                            <th rowSpan="2" colSpan="3">C1<br/><small>Intelligence<br/>(45%)</small></th>
                            <th rowSpan="2" colSpan="3">C2<br/><small>Beauty<br/>(55%)</small></th>
                            <th rowSpan="3">Score</th>
                            <th rowSpan="3">Rank</th>
                        </tr>

                        <tr>
                            <th colSpan="2">1</th>
                            <th colSpan="2">2</th>
                            <th colSpan="2">3</th>
                            <th colSpan="2">4</th>
                            <th colSpan="2">5</th>
                        </tr>

                        <tr>
                            <td>C1</td>
                            <td>C2</td>
                            <td>C1</td>
                            <td>C2</td>
                            <td>C1</td>
                            <td>C2</td>
                            <td>C1</td>
                            <td>C2</td>
                            <td>C1</td>
                            <td>C2</td>
                            <th>T</th>
                            <th>A</th>
                            <th>%</th>
                            <th>T</th>
                            <th>A</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((contestant, index) => {
                            var tie = 0;
                            for (let i = index - 1; i > -1; i--) {
                                if (list[i].score === contestant.score) {
                                    tie++;
                                }
                            }
                            const pos = index + 1 - tie;

                            const active = selected.find((sel) => sel.id === contestant.id);
                            return (
                                <ResultsTop10tem contestant={contestant} rank={pos} key={contestant.id} onItemAddHandler={onItemAddHandler} active={active}  c1per={4.5} c2per={5.5}/>
                            )
                        })}
                    </tbody>
                    </table>
                </div>

                <div className='columns my-6'>
                    {judges.map((judge, index) => {
                        return (
                            <div className='column is-one-fifth' key={judge.uid}><p className='has-text-centered has-text-weight-medium is-size-5' style={{borderBottom: "1px solid #dbdbdb"}}>{judge.name}</p><p className='has-text-centered'>{judge.uid.charAt(0).toUpperCase() + judge.uid.substring(1).toLowerCase()}</p></div>
                        )
                    })}
                </div>
                </div>
                    <button type="button" className="is-fullwidth button is-info block is-large column is-8 is-offset-2 my-6" onClick={onPrintHandler}>Print</button>
        </div>
    )
}

export default RankingTop5;