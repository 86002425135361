import { getImage } from '../utils/utils';
import FormInput from './form-input'

const TopScoringItem = ({contestant, index, c1, c2, onC1ValueChange, onC2ValueChange, locked}) => {
    const {name, number} = contestant;

    return (
        <div className="column is-4">
            <div className="card">
                <div className="card-image">
                    <figure className="image is-4by5" style={{overflow: "hidden"}}>
                        <img src={getImage(number)} alt={name} style={{height: "initial"}}/>
                    </figure>
                </div>
                <div className="card-content">
                    <div className="media">
                        <div className="media-content">
                            <p className="title is-size-3">#{number} {name}</p>
                            
                            <FormInput disabled={locked} additionalClasses="mt-5" type="number" required name={index} value={c1} onChange={(e) => {onC1ValueChange(e)}} step="0.01" min="1" max="10" label="Intelligence"/>
                            <FormInput disabled={locked} additionalClasses="my-3" type="number" required name={index} value={c2} onChange={(e) => {onC2ValueChange(e)}} step="0.01" min="1" max="10" label="Beauty"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopScoringItem;