import { useState, useEffect, useContext, Fragment } from 'react';
import { getContestants, setLocked } from '../utils/firebase';
import { UserContext } from "../context/user-context";
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import RankingItem from '../components/ranking-item';
import { getCategoryName, getCategoryPercentage } from '../utils/utils';

const Ranking = () => {
    const [list, setList = () => []] = useState([]);
    const { currentUser } = useContext(UserContext);
    const navigate = useNavigate();
    const { category } = useParams();
    const key = currentUser.data.type === "superadmin" ? category : currentUser.data.uid + "_" + category;
    const { state } = useLocation();
    const locked = state ? state.locked : false;

    useEffect(() => {
        async function getDocs() {
            const docs = await getContestants("Contestants", key);
            setList(docs);
        };
        getDocs();
    }, [category]);

    const onClickHandler = async() => {
        const lock = await setLocked(currentUser.uid, category + "_locked");
        if (lock === "success") {
            navigate("/");
        } else {
            alert(lock);
        }
    }

    const alertDialog = () => {
        var res = window.confirm("Are you sure you want to finalize rating?");
        if (res) {onClickHandler();}
    }
    
    const cat = category.toUpperCase();
    var txt = getCategoryName(cat);
    var per = getCategoryPercentage(cat);

    return (
        <div className='column is-8 is-offset-2'>
            <p className='is-size-4 has-text-centered'>Preliminaries</p>
            <h1 className='is-size-1 has-text-weight-bold has-text-centered mb-6'>{txt} — {per}%</h1>

            <div className="table-container mt-6">
                <table className="table is-fullwidth is-hoverable is-borderless">
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Image</th>
                            <th className='has-text-left'>Name</th>
                            <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((contestant, index) => {
                            var tie = 0;
                            for (let i = index - 1; i > -1; i--) {
                                if (list[i][key] === contestant[key]) {
                                    tie++;
                                }
                            }
                            const pos = index + 1 - tie;

                            return (
                                <RankingItem contestant={contestant} rank={pos} key={contestant.id} score={contestant[key]}/>
                            )
                        })}
                    </tbody>
                    </table>
                </div>
                <div className='columns my-6'>
                    {locked ? <button type="button" className="is-fullwidth button is-info block is-large column is-12" onClick={() => {navigate("/")}}>Return to Home</button> : <Fragment><button type="button" className="is-fullwidth button is-info block is-large column is-2" onClick={() => {navigate("/preliminaries/" + category)}}>Edit</button>
                    <button type="button" className="is-fullwidth button is-danger block is-large column is-9 is-offset-1" onClick={alertDialog}>Finalize</button></Fragment>}
                </div>
        </div>
    )
}

export default Ranking;