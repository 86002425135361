const ResultsTop10tem = ({contestant, rank, onItemAddHandler, active, c1per, c2per}) => {
    const {name, number, id, judge1_c1, judge1_c2, judge2_c1, judge2_c2, judge3_c1, judge3_c2, judge4_c1, judge4_c2, judge5_c1, judge5_c2} = contestant;

    const c1 = judge1_c1 + judge2_c1 + judge3_c1 + judge4_c1 + judge5_c1;
    const c2 = judge1_c2 + judge2_c2 + judge3_c2 + judge4_c2 + judge5_c2;
    
    const c1a = c1 / 5;
    const c2a = c2 / 5;

    const c1p = c1a * c1per;
    const c2p = c2a * c2per;

    const tot = c1p + c2p;

    contestant["score"] = tot;

    return (
        <tr className={`is-size-6 has-text-vcentered ${active ? "is-active" : ""}`} onClick={() => {onItemAddHandler ? onItemAddHandler({name, number, id}) : console.log("")}}>
            <td>{number}</td>
            <td className='has-text-weight-medium has-text-left'>{name}</td>
            <td>{judge1_c1}</td>
            <td>{judge1_c2}</td>

            <td>{judge2_c1}</td>
            <td>{judge2_c2}</td>

            <td>{judge3_c1}</td>
            <td>{judge3_c2}</td>

            <td>{judge4_c1}</td>
            <td>{judge4_c2}</td>

            <td>{judge5_c1}</td>
            <td>{judge5_c2}</td>

            <td>{c1 ? c1.toFixed(2) : ""}</td>
            <td>{c1a ? c1a.toFixed(2) : ""}</td>
            <td>{c1p ? c1p.toFixed(2) + "%" : ""}</td>
            <td>{c2 ? c2.toFixed(2) : ""}</td>
            <td>{c2a ? c2a.toFixed(2) : ""}</td>
            <td>{c2p ? c2p.toFixed(2) + "%" : ""}</td>
            <td>{tot ? tot.toFixed(2) + "%" : ""}</td>
            {rank ? <td>{rank}</td> : ""}
        </tr>
    )
}

export default ResultsTop10tem;