import { getCategoryPercentage, getJudgesCount } from "../utils/utils";

const ResultsPreliminariesItem = ({contestant, rank, onItemAddHandler, active}) => {
    const {name, number, id,
        judge1_c1, judge1_c2, judge1_c3, judge1_c4,
        judge2_c1, judge2_c2, judge2_c3, judge2_c4,
        judge3_c1, judge3_c2, judge3_c3, judge3_c4,
        judge4_c1, judge4_c2, judge4_c3, judge4_c4,
        judge5_c1, judge5_c2, judge5_c3, judge5_c4,
        judge6_c1, judge6_c2,
    } = contestant;

    const c1 = judge1_c1 + judge2_c1 + judge3_c1 + judge4_c1 + judge5_c1 + judge6_c1;
    const c2 = judge1_c2 + judge2_c2 + judge3_c2 + judge4_c2 + judge5_c2 + judge6_c2;
    const c3 = judge1_c3 + judge2_c3 + judge3_c3 + judge4_c3 + judge5_c3;
    const c4 = judge1_c4 + judge2_c4 + judge3_c4 + judge4_c4 + judge5_c4;
    
    const c1a = c1 / getJudgesCount("C1");
    const c2a = c2 / getJudgesCount("C2");
    const c3a = c3 / getJudgesCount("C3");
    const c4a = c4 / getJudgesCount("C4");

    const c1p = (c1a * getCategoryPercentage("C1")) / 10;
    const c2p = (c2a * getCategoryPercentage("C2")) / 10;
    const c3p = (c3a * getCategoryPercentage("C3")) / 10;
    const c4p = (c4a * getCategoryPercentage("C4")) / 10;

    const tot = c1p + c2p + c3p + c4p;

    contestant["score"] = tot;

    return (
        <tr className={`is-size-6 has-text-vcentered ${active ? "is-active" : ""}`} onClick={() => {onItemAddHandler ? onItemAddHandler({name, number, id}) : console.log("")}}>
            <td>{number}</td>
            <td className='has-text-weight-medium has-text-left'>{name}</td>
            <td>{judge1_c1}</td>
            <td>{judge1_c2}</td>
            <td>{judge1_c3}</td>
            <td>{judge1_c4}</td>

            <td>{judge2_c1}</td>
            <td>{judge2_c2}</td>
            <td>{judge2_c3}</td>
            <td>{judge2_c4}</td>

            <td>{judge3_c1}</td>
            <td>{judge3_c2}</td>
            <td>{judge3_c3}</td>
            <td>{judge3_c4}</td>

            <td>{judge4_c1}</td>
            <td>{judge4_c2}</td>
            <td>{judge4_c3}</td>
            <td>{judge4_c4}</td>

            <td>{judge5_c1}</td>
            <td>{judge5_c2}</td>
            <td>{judge5_c3}</td>
            <td>{judge5_c4}</td>

            <td>{judge6_c1}</td>
            <td>{judge6_c2}</td>

            <td>{c1 ? c1.toFixed(2) : ""}</td>
            <td>{c1a ? c1a.toFixed(2) : ""}</td>
            <td>{c1p ? c1p.toFixed(2) + "%" : ""}</td>
            <td>{c2 ? c2.toFixed(2) : ""}</td>
            <td>{c2a ? c2a.toFixed(2) : ""}</td>
            <td>{c2p ? c2p.toFixed(2) + "%" : ""}</td>
            <td>{c3 ? c3.toFixed(2) : ""}</td>
            <td>{c3a ? c3a.toFixed(2) : ""}</td>
            <td>{c3p ? c3p.toFixed(2) + "%" : ""}</td>
            <td>{c4 ? c4.toFixed(2) : ""}</td>
            <td>{c4a ? c4a.toFixed(2) : ""}</td>
            <td>{c4p ? c4p.toFixed(2) + "%" : ""}</td>

            <td>{tot ? tot.toFixed(2) + "%" : ""}</td>
                {rank ? <td>{rank}</td> : ""}
        </tr>
    )
}

export default ResultsPreliminariesItem;