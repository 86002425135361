import FormInput from './form-input'
import { getCategoryName } from '../utils/utils';
import { Fragment } from 'react';

const JudgesItem = ({judge, name, index, onValueChange}) => {
    const {uid, c1_locked, c2_locked, c3_locked, top5_locked, top10_locked} = judge;

    return (
        <div className="column is-4">
            <div className="card">
                <div className="card-content">
                    <div className="media">
                        <div className="media-content">
                            <p className="title is-size-3">{uid.toUpperCase()}</p>
                            <label className="checkbox is-block">
                                <input type="checkbox" className='mr-2' checked={c1_locked}/>
                                {getCategoryName("C1")}
                            </label>
                            <label className="checkbox is-block">
                                <input type="checkbox" className='mr-2' checked={c2_locked}/>
                                {getCategoryName("C2")}
                            </label>

                            {uid !== "judge6" ? <Fragment>
                                <label className="checkbox is-block">
                                    <input type="checkbox" className='mr-2' checked={c3_locked}/>
                                    {getCategoryName("C3")}
                                </label>
                                <label className="checkbox is-block">
                                    <input type="checkbox" className='mr-2' checked={c3_locked}/>
                                    {getCategoryName("C4")}
                                </label>


                                <label className="checkbox is-block">
                                    <input type="checkbox" className='mr-2' checked={top10_locked}/>
                                    Top 10
                                </label>
                                
                                <label className="checkbox is-block">
                                    <input type="checkbox" className='mr-2' checked={top5_locked}/>
                                    Top 5
                                </label>
                            </Fragment> : ""}
                            <FormInput additionalClasses="mt-5 mb-3" type="text" required id={index} value={name} onChange={(e) => {onValueChange(e)}} label="Full Name"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default JudgesItem;