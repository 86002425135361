import { Fragment } from 'react'
import { getJudgesCount } from '../utils/utils';

const ResultsCategoryItem = ({contestant, rank, onItemAddHandler, active, percentile, category}) => {
    const {name, number, id, cat_score } = contestant;

    const cat = category.toUpperCase();
    const count = getJudgesCount(cat);
    const c1a = cat_score / count;

    const c1p = c1a * percentile;

    const items = [];
    for (let i = 1; i <= count; i++) {
        const item = contestant["judge" + i + "_" + category];
        items.push(item);
    }

    return (
        <tr className={`is-size-6 has-text-vcentered ${active ? "is-active" : ""}`} onClick={() => {onItemAddHandler ? onItemAddHandler({name, number, id}) : console.log("")}}>
            <td>{number}</td>
            <td className='has-text-weight-medium has-text-left'>{name}</td>
            {items.map((item) => {
                return (
                    <td>{item}</td>
                )
            })}
            <td>{cat_score ? cat_score.toFixed(2) : ""}</td>
            <td>{c1a ? c1a.toFixed(2) : ""}</td>
            <td>{c1p ? c1p.toFixed(2) + "%" : ""}</td>

            {rank ? <td>{rank}</td> : ""}
        </tr>
    )
}

export default ResultsCategoryItem;