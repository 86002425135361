import { initializeApp } from 'firebase/app'
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth'
import { getFirestore, doc, getDoc, setDoc, writeBatch, addDoc, collection, query, where, getDocs, orderBy, startAt, endAt, updateDoc, getCountFromServer } from 'firebase/firestore'
 
const firebaseConfig = {
  apiKey: "AIzaSyAdzWFdh3bJCK1eme6A7ofJJ1lc-wt2wpw",
  authDomain: "tabulation-775b8.firebaseapp.com",
  projectId: "tabulation-775b8",
  storageBucket: "tabulation-775b8.appspot.com",
  messagingSenderId: "551211179688",
  appId: "1:551211179688:web:e507cd95731321ccab2c9d"
};
  
  const app = initializeApp(firebaseConfig);

  export const auth = getAuth();
  
  export const db = getFirestore();

  export const createAuthUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    return await createUserWithEmailAndPassword(auth, email, password);
  }

  export const signInUserWithEmailAndPassword = async (email, password) => {
    if (!email || !password) return;

    return await signInWithEmailAndPassword(auth, email, password);
  }

  export const signOutUser = async () => await signOut(auth);

  export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);

  export const createUserDocument = async (userAuth, additionalInformation = {}) => {
    if (!userAuth) return;
    
    const userDocRef = doc(db, 'Users', userAuth.uid);
    const userSnapshot = await getDoc(userDocRef);
    var data = userSnapshot.data();

    if (!userSnapshot.exists()) {
        const created = new Date();
        data =  {
          created,
          ...additionalInformation
       };

        try {
            await setDoc(userDocRef, data)
        } catch (error) {
            console.log("error", error.message);
        }
    }
    
    userAuth["data"] = data;

    return userDocRef;
  }

  export const getContestants = async (col, key) => {
    const docRef = collection(db, col);
    const docs = [];
    var dir = key === "number" ? "asc" : "desc";
    var q = query(docRef, orderBy(key, dir));

    try {
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          docs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return docs;
  }

  export const setLocked = async (id, key) => {
    const userDocRef = doc(db, 'Users', id);
    var response;

    try {
      await updateDoc(userDocRef, {[key]: true});
      response = "success";
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const checkLocked = async (id, key) => {
    const docRef = doc(db, "Users", id);
    
    var locked = false;
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      locked = docSnap.data()[key] ? docSnap.data()[key] : false;
    } else {
      console.log("No such document!");
    }

    return locked;
  }

  export const getJudges = async () => {
    const docRef = collection(db, "Users");
    const docs = [];
    var q = query(docRef, where("type", "==", "judge"), orderBy("uid", "asc"));

    try {
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
          const data = doc.data();
          data["id"] = doc.id;
          docs.push(data);
      });

    } catch (error) {
      console.log("error", error.message);
    }

    return docs;
  }

  export const setJudgesName = async (list, names) => {
    const batch = writeBatch(db);

    list.forEach((item, index) => {
      const sfRef = doc(db, "Users", item.id);
      batch.update(sfRef, {"name": names[index]});
    });

    var response = "success";
    try {
      await batch.commit();
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const setContestantScores = async (key, list, scores) => {
    const batch = writeBatch(db);

    list.forEach((item, index) => {
      const sfRef = doc(db, "Contestants", item.id);
      batch.update(sfRef, {[key]: parseFloat(parseFloat(scores[index]).toFixed(2))});
    });

    var response = "success";
    try {
      await batch.commit();
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const setTopContestantScores = async (col, c1i, c2i, ct, list, c1s, c2s, c1p, c2p) => {
    const batch = writeBatch(db);

    list.forEach((item, index) => {
      const sfRef = doc(db, col, item.id);
      const c1 = parseFloat(parseFloat(c1s[index]).toFixed(2));
      const c2 = parseFloat(parseFloat(c2s[index]).toFixed(2));
      const tot = ((c1 * c1p) + (c2 * c2p)) * 2;
      batch.update(sfRef, {[c1i]: c1, [c2i]: c2, [ct]: parseFloat(parseFloat(tot.toFixed(2)))});
    });

    var response = "success";
    try {
      await batch.commit();
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const setContestantsScore = async (col, list) => {
    const batch = writeBatch(db);

    list.forEach((item, index) => {
      const sfRef = doc(db, col, item.id);
      batch.update(sfRef, {"score": parseFloat(parseFloat(item.score.toFixed(2)))});
    });

    var response = "success";
    try {
      await batch.commit();
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const setTopContestants = async (col, list) => {
    const batch = writeBatch(db);

    list.forEach((item, index) => {
      const sfRef = doc(db, col, item.id);
      batch.set(sfRef, {"number": item.number, "name": item.name});
    });

    var response = "success";
    try {
      await batch.commit();
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const resetContestants = async (list) => {
    const batch = writeBatch(db);

    list.forEach((item, index) => {
      const sfRef = doc(db, "Contestants", item.id);
      batch.set(sfRef, {"number": item.number, "name": item.name});
    });

    var response = "success";
    try {
      await batch.commit();
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const resetJudges = async (list) => {
    const batch = writeBatch(db);

    list.forEach((item, index) => {
      const sfRef = doc(db, "Users", item.id);
      batch.set(sfRef, {"type": item.type, "name": item.name, "uid": item.uid});
    });

    var response = "success";
    try {
      await batch.commit();
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }

  export const deleteContestants = async (col, list) => {
    const batch = writeBatch(db);

    list.forEach((item, index) => {
      const sfRef = doc(db, col, item.id);
      batch.delete(sfRef);
    });

    var response = "success";
    try {
      await batch.commit();
    } catch (error) {
      response = "error: " + error.message;
    }

    return response;
  }