import { getImage } from '../utils/utils';
import FormInput from './form-input'

const ScoringItem = ({contestant, score, index, onValueChange, locked}) => {
    const {name, number} = contestant;

    return (
        <div className="column is-4">
            <div className="card">
                <div className="card-image">
                    <figure className="image is-4by5" style={{overflow: "hidden"}}>
                        <img src={getImage(number)} alt={name} style={{height: "initial"}}/>
                    </figure>
                </div>
                <div className="card-content">
                    <div className="media">
                        <div className="media-content">
                            <p className="title is-size-3">#{number} {name}</p>
                            
                            <FormInput disabled={locked} additionalClasses="mt-5 mb-3" type="number" required id={index} value={score} onChange={(e) => {onValueChange(e)}} step="0.01" min="1" max="10" label="Score"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScoringItem;